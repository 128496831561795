<template>
    <transition v-if="!loading" name="fade">
        <div id="wrapper">
            <sidebar :page="'Supplier Orders'" :sidebar_data=sidebar_data></sidebar>
            <div class="body">
                <div class="backdrop" v-on:click="close()"></div>
                
                <admin-header :title="supplier.name" :tagline="supplier.address" v-model:all_screens=all_screens v-model:screen=screen v-model:url=url  :user=user style="margin-bottom: 3rem;"></admin-header>

                <div class="container mb-4">
                    <div class="content-wrapper">
                        <div class="content mx-auto">
                            <status v-model:status_data=status_data></status>

                            <div id="cards" class="row mb-3">
                                <div class="col-md-12 col-lg-12 mt-3" v-for="({id, delivery_date, order_items, status}, index) in orders" :key="index">
                                    <div class="card mb-2 border-0">
                                        <div class="card-body pb-0">
                                            <div class="d-flex">
                                                <p class="text-dark fw-bold">Order ID: {{ id }}</p>
                                                <p class="text-muted ms-auto text-uppercase">
                                                    <span :class="['text-uppercase', 'fw-bold', 
                                                        {'text-warning': status == 'pending'},
                                                        {'text-primary': status == 'accepted'},
                                                        {'text-danger': status == 'rejected'},
                                                        {'text-info': status == 'fulfilled'},
                                                        {'text-success': status == 'confirmed'}]">{{ status }}</span>
                                                    <font-awesome-icon :class="['ms-2', 
                                                        {'text-warning': status == 'pending'},
                                                        {'text-primary': status == 'accepted'},
                                                        {'text-danger': status == 'rejected'},
                                                        {'text-info': status == 'fulfilled'},
                                                        {'text-success': status == 'confirmed'},
                                                    ]" :icon="['fas', 'circle']"></font-awesome-icon>
                                                </p>
                                            </div>

                                            <div class="bg-secondary mb-4" style="padding-top: 1px"></div>

                                            <div class="row">
                                                <div class="col-md-6 col-sm-12" v-for="({name, quantity, stock_type, description}, index) in order_items" :key="index">
                                                    <p class="text-dark fst-italic">{{ index+1 }}.</p>
                                                    
                                                    <p class="text-dark mb-3"> 
                                                        <span class="fw-bold text-uppercase">
                                                            Name:
                                                        </span> 
                                                        {{ name }}
                                                    </p>

                                                    <p class="text-dark mb-3"> 
                                                        <span class="fw-bold text-uppercase">
                                                            Type:
                                                        </span> 
                                                        {{ stock_type }}
                                                    </p>

                                                    <p class="text-dark mb-3"> 
                                                        <span class="fw-bold text-uppercase">
                                                            Quantity:
                                                        </span> 
                                                        {{ quantity }}
                                                    </p>

                                                    <p class="text-dark mb-3"> 
                                                        <span class="fw-bold text-uppercase">
                                                            Description
                                                        </span> 
                                                        {{ description }}
                                                    </p>
                                                </div>
                                            </div>   

                                            <div class="bg-secondary mb-3" style="padding-top: 1px"></div>

                                            <div class="d-flex mb-2">
                                                <p class="text-muted mt-1 mb-0">Deliver by: {{ delivery_date }}</p>

                                                <div class="text-muted ms-auto" v-if="status == 'pending'">
                                                    <button class="btn btn-primary btn-sm" @click="updateOrder('accepted', id)">Accept Order</button>

                                                    <button class="btn btn-danger btn-sm ms-2" @click="updateOrder('rejected', id)">Deny Order</button>
                                                </div>

                                                <div class="text-muted ms-auto" v-if="status == 'accepted'" @click="updateOrder('fulfilled', id)">
                                                    <button class="btn btn-primary text-white btn-sm">Fulfil Order</button>
                                                </div>
                                            </div>
                            
                                        </div>
                                    </div>
                                </div>   

                                <div class="col-md-12 col-lg-12 d-flex mt-4">
                                    <div class="mx-auto">
                                        <button class="btn bg-orange text-white me-2" @click="loadPrevious()" v-if="page > 0"> 
                                            <font-awesome-icon :icon="['fas', 'angle-left']"></font-awesome-icon>
                                        </button>
                                        
                                        <button class="btn bg-orange text-white text-uppercase" @click="loadMore()"> 
                                            <p class="m-0 ps-2 pe-2" style="letter-spacing: 1px">Load more</p>
                                        </button>
                                    </div>
                                </div>  
                            </div>
                        </div>                                          
                    </div>
                </div>
            </div>

            <add-user-to-supplier></add-user-to-supplier>
        </div>
    </transition>
</template>

<style lang="scss" scoped>
    @import '../../assets/scss/layout/_lists.scss';

    #cards {
        display: block!important;
    }
</style>

<script>
import Sidebar from '../../components/AutoSidebar'
import AdminHeader from '../../components/Header'
import Status from '../../components/Status'
import AddUserToSupplier from '../../components/Modals/AddUsertoSupplier'

export default {
    name: 'SupplierOrders',
    components: {
        AdminHeader,
        Sidebar,
        Status,
        AddUserToSupplier
    },
    data() {
        return {
            orders: [],
            size: 25,
            page: 0,
            last_id: 0,
            totals: null,
            status: 'pending',
            title: {status: false},
            sidebar_data: [
                {title: 'Manage Admins', link: 'suppliers/users', if_children: false, icon: 'boxes', modal: false},
                {title: 'Supplier Stocks', link: 'suppliers/stocks', if_children: false, icon: 'boxes', modal: false},
                {title: 'Supplier Orders', link: 'suppliers/orders', if_children: false, icon: 'hands-helping', modal: false},
            ],   
            all_screens: [
                {title: 'Our Orders', screen: 1, url: 'api/stocks/supplier/1'}
            ], 
            screen: 1,
            status_data: {
                "success": false,
                "success_message": 'Form Submission was successful!',
                "error": false,
                "error_message": 'There are some errors.',
                "loading": false
            },      
            first_order: null,
            notification: [],
            notification_count: null ,
            notified_ids: [],
            supplier: {},
            url: null
        }
    },
    watch: {
        user: function() {
            this.ifSupplier()
        }
    },
    computed: {
        user () {
            return this.$store.state.user
        },
        loading () {
            return this.$store.state.loading
        }       
    },      
    mounted() {
        this.$store.dispatch("getUser")

        //  Set checker thingy
        // setInterval(() => {
        //     this.notifyOrders()
        // }, 10000); 
    },
    methods: {
        ifSupplier() {
            if( !this.user.supplier) {
                this.$router.push({name: 'Home'});
            } else {
                this.url = 'api/suppliers-users/' + this.user.supplier_id
                this.getSupplier(this.user.supplier_id)
                this.getOrders()
            }
        },
        getSupplier(id) {
            this.axios.get('api/suppliers/' + id).then(response => {
                this.supplier = response.data.data
                console.log(response);
            }).catch(() => {
                this.status_data.errors = true
                this.status_data.error_message = 'Couldn\'t find Supplier details.'
            })
        },         
        getOrders() {
            let url = '/api/orders' 

            this.axios.get(url + '?size=' + this.size + '&page=' + this.page + '&last_id=' + this.last_id).then((response) => {
                this.orders = response.data.data
                this.last_id = response.data.last_id
                this.totals = response.data.totals
                this.first_order = response.data.data[0]
            }).catch((errors) => {
                console.log(errors);
            })  
        },
        generateNotification(element) {
            let val = ''

            if(element.status == 'pending') {
                val = 'You have a new order pending, ID ' + element.id + ', with a delivery date for ' + element.delivery_date
            } else if(element.status == 'confirmed') {
                val = 'The order ' + element.id +  ' has been completed!'
            }

            if(this.notified_ids.indexOf(element.id) == '-1') {
                this.notified_ids.push(element.id)
                return {id: element.id, notification: val}
            }

            return false
        },
        notifyOrders() {
            let url = '/api/orders/supplier/2' 

            this.axios.get(url + '?size=' + this.size + '&page=' + this.page + '&last_id=' + this.last_id).then((response) => {
                let i = 0

                if(this.first_order.id != response.data.data[0].id) {
                    for (let index = 0; index < response.data.data.length; index++) {
                        let element = response.data.data[index]

                        if(element.id == this.first_order.id) {
                            break
                        } else {
                            let notification = this.generateNotification(element)
                            if(notification != false) {
                                this.notification.push(notification)
                            }
                        }

                        i++
                    }  
                }

                this.notification_count = i

                console.log(this.notification);
                console.log(this.notification_count);
                console.log('-----------------------');
            }).catch((errors) => {
                console.log(errors);
            }) 
        },
        loadMore() {
            this.page = this.page + 1
            this.getOrders()          
        },
        loadPrevious() {
            if(this.page > 0) {
                this.page = this.page - 1
            }

            this.getOrders()
        },
        updateOrder(value, id) {
            this.status_data.loading = true
            let status = {
                status: value,
                supplier_id: this.user.supplier_id
            }

            this.axios.put('/api/orders/' + id, status).then(() => {
                this.status_data.loading = false
                this.status_data.error = false
                this.status_data.success = true
                this.status_data.success_message = 'Order updated!'

                this.getOrders()
            }).catch((error) => {
                this.status_data.loading = false
                this.status_data.error = true
                this.status_data.success = false
                this.status_data.error_message = 'Order not updated.' + error.response.data.message
            })
        }
    }

}
</script>
